<template>
  <div class="category">
    <div class="category__label">
      <label v-if="label">{{ label }}</label>
    </div>
    <div class="slider-div">
      <span v-if="value && value[0]">{{ value[0] }}</span>
      <vue-slider ref="slider" :max="maxRange" :min="minRange" @drag-end="updateRange" v-model="value" />
      <span v-if="value && value[1]">{{ value[1] }}</span>
    </div>
    <div class="tag">
      <span>Range :</span>
      <span>${{ minRange }} - ${{ maxRange }}</span>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    minRange: {
      type: Number,
      default: 1
    },
    maxRange: {
      type: Number,
      default: 5000
    },
    price: {
      type: Array,
      default: () => [1, 5000]
    }
  },
  components: {
    VueSlider
  },
  data () {
    return {
      value: [1, 5000]
    }
  },
  mounted() {
    this.value = this.price
  },
  methods: {
    updateRange() {
      setTimeout(() => {
        this.$emit('range', this.value)
      }, 500)
    }
  }
}
</script>
<style lang="sass" scoped>
.tag
  display: flex
  margin-top: 15px
  span
    @include font-source('Poppins', 13px, 600, $text-grey)
    &:first-child
      margin-right: 10px
    &:nth-child(2)
      color: $black
      font-weight: 700
.slider-div
  display: flex
  justify-content: space-between
  align-items: center
  span
    background: $primary-text
    color: $white
    font-size: 14px
    width: 50px
    padding: 0 5px
    &:first-child
      margin-right: 15px
    &:last-child
      margin-left: 15px
</style>
<style lang="sass">
.vue-slider
  padding: 0 !important
  width: 100% !important
  height: 2px !important
  background: $primary-text !important
.vue-slider-rail
  // background-color: $primary-text !important
  .vue-slider-process
    background-color: $primary-text !important
    height: 2px !important
  .vue-slider-dot-handle
    border-radius: 0% !important
    border: 2px solid $primary-text !important
  .vue-slider-dot-tooltip
    .vue-slider-dot-tooltip-inner
      span.vue-slider-dot-tooltip-text
        color: $white !important
</style>
