<template>
  <div class="category">
    <div class="category__label">
      <label v-if="label">{{ label }}</label>
    </div>
    <div>
      <router-link :to="{ name: 'ProductDetails', params: { id: item.id } }" v-for="(item, index) in items" :key="index" class="list-wrapper">
        <div class="items">
          <figure class="items__image">
            <!-- <img src="@/assets/icons/sofa.svg"> -->
            <img v-if="item.image && item.image[0] && item.image[0].url" :src="getMediaURL(item.image[0], 'medium')" />
            <img src="@/assets/images/no-pictures.png" v-else>
          </figure>
          <div class="items__content">
            <div class="rating">
              <Rating :isShow="true" :isActive="false" />
            </div>
            <h4 v-if="item['Name']">{{ item['Name'] }}</h4>
            <div v-if="userDetails && userDetails.id" class="price">
              <span v-if="item['Discount']" class="discount">${{ item['Discount'] }}</span>
              <span v-if="item['Price']">${{ item['Price'].toFixed(2) }}</span>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import getMediaURL from '@/mixins/getMediaURL'
import Rating from '@/components/organisms/Rating'
export default {
  mixins: [getMediaURL],
  props: {
    label: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Rating
  },
  computed: {
    ...mapState('auth', ['userDetails'])
  }
}
</script>

<style lang="sass" scoped>
%text-props
  @include font-source('Poppins', 14px, 600, $text-grey)
.list-wrapper
  border-bottom: 1px solid $grey-2
  border-style: dashed
  text-decoration: none
  &:first-child
    .items
      padding-top: 0
  &:last-child
    border-bottom: none
  .items
    display: flex
    padding: 10px 0 10px 0
    &__image
      width: 100px
      height: 100px
      img
        max-width: 100%
    &__content
      flex: 1
      display: flex
      align-items: flex-start
      flex-direction: column
      margin-left: 15px
      h4
        text-transform: capitalize
        text-align: left
        @extend %text-props
      .price
        span
          font-size: 13px
          color: #ff5745
          font-weight: 600
          @extend %text-props
          &.discount
            font-weight: 400
            color: $text-grey
            margin-right: 5px
            text-decoration: line-through
</style>
