<template>
  <div v-if="items && items.length > 0" class="category">
    <div class="category__label">
      <label v-if="label">{{ label }}</label>
    </div>
    <ul v-if="isCategory || isColor" class="category__list">
      <li :class="{'active': selectedID === item.id, 'isColor': isColor && item.code}" class="pointer" v-for="(item, index) in items" :key="index" @click="selectedItem(item)">
        <span>
          <div v-if="isColor && item.code" v-bind:style="{ background: item.code }" />
          {{ item['name'] }}
        </span>
        <span v-if="item">{{ item['productsCount'] }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    isCategory: { // for show CategoryList use isCategory props
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    isColor: { // for show color use isColor props
      type: Boolean,
      default: false
    },
    selectedID: [Number, Boolean]
  },
  methods: {
    selectedItem: function (item) {
      this.$emit('select', item)
    }
  }
}
</script>

<style lang="sass" scoped>
%text-props
  @include font-source('Poppins', 13px, 600, $text-grey)
.category
  &__list
    li
      display: flex
      justify-content: space-between
      margin: 10px 0
      font-size: 14px
      &.isColor
        margin: 16px 0
        span
          display: flex
          div
            width: 30px
            height: 30px
            border-radius: 50%
            margin-right: 8px
      span
        @extend %text-props
        &:nth-child(2)
          background: map-get($greyShades, 'greyShade1')
          padding: 2px 10px
          border-radius: 50%
          font-size: 11px
      &.active
        span
          &:first-child
            color: $orange
</style>
