<template>
  <div class="rating">
    <ul class="list">
      <li v-for="(star, index) in stars" :key="index" :class="{'pointer': !isShow}" class="star" @click="!isShow? rate(index) : ''">
        <img src="@/assets/icons/rating-star-active.svg" v-if="index < starsActive || isActive">
        <img src="@/assets/icons/rating-star.svg" v-else>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Rating',
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      stars: [1,2,3,4,5],
      starsActive: 0
    }
  },
  methods: {
    rate(index) {
      this.starsActive = index + 1
      this.$emit('rate', this.starsActive)
    }
  }
}
</script>
<style scoped lang="sass">
.rating
  ul
    display: flex
    flex-wrap: wrap
    li
      width: 12px
      margin-right: 2px
</style>