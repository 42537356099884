<template>
  <div>
    <TopSection from="Home" page="Product Catalog" />
    <div v-if="!isLoader" class="product-wrap center-wrapper">
      <span @click="isMenu = true" class="menu-icon pointer" v-if="productList && productList.length > 0">&#9776;</span>
      <div :class="{'isMenu': isMenu}" class="sidebar sidenav">
        <span class="closebtn pointer" @click="isMenu = false">&times;</span>
        <ProductFilter :isCategory="true" :selectedID="getWareHouseID" :items="wareHousesList" label='ALL "WAREHOUSES"' @select="selectValue('warehouses', { 'warehouses.id': $event.id })"/>
        <ProductFilter :isCategory="true" :selectedID="getCategoryId" :items="categoriesList" label="ALL CATEGORIES" @select="selectValue('category', { 'category.id': $event.id })"/>
       <!-- <ProductFilter :isCategory="true" :selectedID="getSubCategoryId" :items="subCategoriesList" label="ANY CATEGORIES" @select="selectValue('sub_category', { 'sub_category.id': $event.id })"/> -->
        <!--<ProductFilter :isCategory="true" :selectedID="getCollectionId" :items="collectionList" label="ALL COLLECTION" @select="selectValue('collections', { 'collections.id': $event.id })"/>-->
        <PriceFilter @range="rangeUpdate" :price="getPrice" :minRange="1" :maxRange="5000" label="PRICE" />
        <!-- <ProductFilter :isColor="true" :items="color" label="COLOR" @select="selectCategory"/> -->
        <!-- <SizeFilter :isSize="true" label="SIZE" :items="size" @selectItem="selectSize"/> -->
        <!-- <SizeFilter :isBrand="true" label="BRANDS" :items="size" @selectItem="selectBrand"/> -->
        <FeatureProducts :items="featuredProducts" label="FEATURE PRODUCT" />
      </div>
        <div class="product-list">
          <ProductList :list="productList" />
        </div>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/molecules/Loader'
import TopSection from '@/components/molecules/TopSection'
import ProductFilter from '@/components/filters/ProductFilter'
import PriceFilter from '@/components/filters/PriceFilter'
// import SizeFilter from '@/components/filters/SizeFilter'
import FeatureProducts from '@/components/filters/FeatureProducts'
import ProductList from '@/components/atoms/ProductList'
export default {
  components: {
    Loader,
    TopSection,
    ProductFilter,
    PriceFilter,
    // SizeFilter,
    FeatureProducts,
    ProductList
  },
  data() {
    return {
      isMenu: false,
      color: [
        {
          id: 1,
          name: 'blue',
          value: 1,
          code: '#337fdd'
        },
        {
          id: 2,
          name: 'brown',
          value: 5,
          code: '#cd7b4b'
        },
        {
          id: 3,
          name: 'green',
          value: 4,
          code: '#53b745'
        }
      ],
      size: [
        {
          id: 1,
          value: 'L'
        },
        {
          id: 2,
          value: 'M'
        },
        {
          id: 3,
          value: 'S'
        },
        {
          id: 4,
          value: 'XL'
        },
        {
          id: 5,
          value: 'XS'
        }
      ],
      featureItems: [
        {
          id: 1,
          name:  'Cole Lounge Chair',
          actualPrice: 130,
          price: 125,
          rating: 4
        },
        {
          id: 2,
          name:  'Copeland Furniture Audrey',
          actualPrice: 100,
          price: 90,
          rating: 0
        },
        {
          id: 3,
          name:  'Della Chair - Navy',
          actualPrice: 100,
          price: 90,
          rating: 0
        }
      ]
    }
  },
  metaInfo() {
    return {
      title: 'Product List'
    }
  },
  computed: {
    ...mapState(['isLoader']),
    ...mapState('products', ['productList', 'categoriesList', 'subCategoriesList', 'collectionList', 'wareHousesList', 'featuredProducts']),
    getCategoryId () {
      let id = 'category.id'
      if (this.$route.query && this.$route.query[id]) {
        return parseInt(this.$route.query[id])
      }
      return 0
    },
    // getSubCategoryId () {
    //   let id = 'sub_category.id'
    //   if (this.$route.query && this.$route.query[id]) {
    //     return parseInt(this.$route.query[id])
    //   }
    //   return 0
    // },
    getCollectionId() {
      let id = 'collections.id'
      return this.$route.query && this.$route.query[id] ? parseInt(this.$route.query[id]) : 0
    },
    getWareHouseID () {
      let id ='warehouses.id'
      return this.$route.query && this.$route.query[id] ? parseInt(this.$route.query[id]) : 0
    },
    getPrice () {
      if (this.$route.query && this.$route.query['Price_lte'] && this.$route.query['Price_gte']) {
        return [parseInt(this.$route.query['Price_lte']), parseInt(this.$route.query['Price_gte'])]
      }
      return [1, 5000]
    }
  },
  watch: {
    '$route' (to, from) {
      this.initialize(this.getQuery())
    }
  },
  created () {
    this.getCategories()
    this.getWarehouses()
    this.initialize(this.getQuery())
    if (this.$route.query['category.id']) {
      this.callToAPI('category', { 'category.id': parseInt(this.$route.query['category.id']) })
    }
  },
  methods: {
    initialize (query) {
      this.$store.dispatch('products/fetchProducts', query)
      this.$store.dispatch('products/fetchFeaturedProducts', { _sort: 'published_at:DESC', _limit: 3, _start: 0 })
    },
    getWarehouses () {
      this.$store.dispatch('products/fetchWareHouses')
    },
    getCategories () {
      this.$store.dispatch('products/fetchCategories')
    },
    callToAPI(type, query) {
      if (type === 'category') {
        this.$store.dispatch('products/fetchCollections', query)
      }
      if (type === 'sub_category') {
        this.$store.dispatch('products/fetchCollections', query)
      }
    },
    getQuery () {
      return this.$route.query
    },
    selectValue (type, data) {
      
      this.callToAPI(type, data)
      this.updateQuery(type, data)
      if (type === 'category') {
        this.$store.commit('products/setCollections', [])
      }
    },
    getWarehouseQuery () {
      if (this.$route.query && this.$route.query['warehouses.id']) {
        return {'warehouses.id': this.$route.query['warehouses.id']}
      }
    },
    updateQuery (type, query) {
      if (this.$route.query && this.$route.query['Price_gte'] && this.$route.query['Price_lte']) {
        query = Object.assign(query, { 'Price_gte': this.$route.query['Price_gte'], 'Price_lte': this.$route.query['Price_lte']})
      }
      if (type === 'category') {
        query = Object.assign(this.getWarehouseQuery() ? this.getWarehouseQuery() : {}, query)
      } else if (type === 'sub_category') {
        const sub_cat_query = {'category.id': this.$route.query['category.id']}
        query = Object.assign(this.getWarehouseQuery() ? Object.assign(this.getWarehouseQuery(), sub_cat_query) : sub_cat_query, query)
      } else if (type === 'collections') {
        const col_query = {'category.id': this.$route.query['category.id'], 'sub_category.id': this.$route.query['sub_category.id']}
        query = Object.assign(this.getWarehouseQuery() ? Object.assign(this.getWarehouseQuery(), col_query) : col_query, query)
      } else {
        query = {...this.$route.query, ...query}
      }
      
      this.$router.push({ name: 'Products', query: query }).catch(err => {
        console.log('duplicate routing')
      })
    },
    rangeUpdate (data) {
      if (data) {
        delete this.$route.query['Price_gte']
        delete this.$route.query['Price_lte']
        this.updateQuery('price', { 'Price_gte': data[0], 'Price_lte': data[1]})
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.product-wrap
  display: flex
  .menu-icon
    position: absolute
    font-size: 38px
  .menu-icon, .closebtn
    @media screen and (min-width: $ms-breakpoint1)
      display: none
  .sidebar
    flex: 0.5
  .product-list
    flex: 1
@media screen and (max-width: $ms-breakpoint)
  .sidenav
    height: 100%
    width: 0
    position: fixed
    z-index: 1
    top: 0
    left: 0
    overflow-x: hidden
    padding-top: 60px
    background: $white
    transition: .3s ease
    &.isMenu
      width: 100%
      z-index: 999
    .closebtn
      display: block
      position: absolute
      top: 20px
      right: 25px
      font-size: 60px
</style>
