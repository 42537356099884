<template>
  <div :class="type" class="products">
    <router-link :to="{ name: 'ProductDetails', params: { id: item.id } }" v-for="(item, index) in items" :key="index" class="product-card">
        <div class="product-card-wrapper">
          <!-- <div class="banner discount" v-if="userDetails && userDetails.id"><span>-10%</span></div> -->
          <div class="banner hot"><span>Hot</span></div>
          <a :href="item.image[0] && item.image[0].url ? item.image[0].url : null" class="product-link">
            <figure class="product-image">
              <img v-if="item.image[0] && item.image[0].url" :src="getMediaURL(item.image[0], 'small')">
              <img src="@/assets/images/no-pictures.png" v-else>
            </figure>
          </a>
          <a class="product-info pointer">
            <a v-if="item['Name']" class="name">{{ item['Name'] }}</a>
            <div v-if="userDetails && userDetails.id" class="product-price">
              <span v-if="item['Discount']" class="ActualPrice">${{ item['Discount'] }}.00</span>
              <span v-if="item['Price']" class="price">${{ item['Price'].toFixed(2) }}</span>
            </div>
          </a>
        </div>
      </router-link>
  </div>
</template>

<script>
import getMediaURL from '@/mixins/getMediaURL'
import { mapState } from 'vuex'
export default {
  mixins: [getMediaURL],
  props: {
    items: {
       type: Array,
       default: () => []
    },
    type: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState('auth', ['userDetails'])
  }
}
</script>

<style lang="sass" scoped>
%text-props
  margin: 0
  @include font-source('Poppins', 13px, 600, $text-grey)
.products
  display: flex
  flex-wrap: wrap
  &.related
    .product-card
      flex: 0 0 25%
      @media screen and (max-width: $lg-breakpoint)
        flex: 0 0 33%
      @media screen and (max-width: $md-breakpoint)
        flex: 0 0 50%
.product-card
  padding: 0px 0.7em 0.9em 0.7em
  transition: all .2s
  text-decoration: none
  &:hover
    transform: scale(1.1)
  flex: 0 0 33%
  padding: 10px
.product-card-wrapper
  position: relative
  .banner
    border-radius: 20px
    z-index: 1
    background: #fff
    position: absolute
    padding: 2px 16px 2px 16px
    font-size: 13px
    font-weight: 600
    top: 10px
    &.discount
      left: 10px
      span
        @extend %text-props
        font-size: 14px
        color: #ff0404
    &.hot
      right: 10px
      span
        color: #e08c46
  a.product-link
    display: flex
    background: map-get($greyShades, 'greyShade1')
    padding: 16px
    .product-image
      width: 100%
      padding-top: 100%
      position: relative
      margin: 0 auto
      img
        position: absolute
        bottom: 0
        left: 0
        right: 0
        top: 0
        margin: auto
        max-width: 100%
        max-height: 100%
a.product-info
  padding: 16px 16px 0 16px
  a.name
    padding-bottom: 5px
    overflow: hidden
    display: block
    @include font-source('Poppins', 16px, 400, $text-grey)
    text-transform: lowercase
    &:first-letter
      text-transform: capitalize
  .product-price
    span
      @include font-source('Poppins', 14px, 600, #ff5745)
      &.ActualPrice
        font-size: 12px
        font-weight: 400
        text-decoration: line-through
        @extend %text-props
      &.price
        margin-left: 5px
</style>