export default {
    data() {
      return {
        isOpen: false,
        openAllSections: false
      }
    },
    mounted() {
      document.addEventListener('click', this.closeMenu)
    },
    destroyed() {
      document.removeEventListener('click', this.closeMenu)
    },
    methods: {
      closeMenu(e) {
        const target = e.target
        if (this.$el && this.$el !== target && !this.$el.contains(target)) {
          this.isOpen = false
          this.openAllSections = false
        }
      }
    }
  }
  