<template>
  <div :class="{'noBorder': noBorder, 'filters': filters}" class="select-wrap">
    <label :for="label" v-if="label"> {{ label }}</label>
    <span @click="isOpen = !isOpen" :class="{'active-dropdown': isOpen}" class="select-option">
      {{ selectedOption }}
    </span>
    <ul v-if="isOpen && isArray && !filters">
      <li v-for="(option, index) in options" :key="index" @click="$emit('selectOption', option); isOpen=false">
        <span>{{ option.value }}</span>
      </li>
    </ul>
    <ul v-if="isOpen && isArray && filters">
      <li v-for="(option, index) in options" :key="index" @click="$emit('selectOption', option); isOpen=false">
        <span>{{ option.name }}</span>
      </li>
    </ul>
    <ul v-else-if="isOpen && !isArray">
      <li v-for="(option, index) in options" :key="index" @click="$emit('selectOption', option); isOpen=false">
        <span>{{ option }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import CloseDropdown from '@/mixins/CloseDropdown'
export default {
  components: {},
  mixins: [CloseDropdown],
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selectedOption: {
      type: String,
      default: ''
    },
    isArray: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      optionSelected: this.selectedOption
    }
  }
}
</script>

<style lang="sass" scoped>
.select-wrap
  display: flex
  position: relative
  cursor: pointer
  flex-direction: column
  label
    align-self: baseline
    @include font-source('Poppins', 13px, 600, $black1)
  &.noBorder
    .select-option
      border: 0
  &.filters
    ul
      li
        padding: 5px
        @include font-source('Poppins', 13px, 600, $black1)
  .select-option
    border: 1px solid $black
    padding: 8px 15px
    display: flex
    align-items: flex-end
    position: relative
    z-index: 2
    @include font-source('Poppins', 13px, 400, $black)
    &:after
      content: ""
      width: 30px
      height: 22px
      background-repeat: no-repeat
      background-image: url(../../assets/icons/arrow.svg)
      position: absolute
      right: 0
    @media screen and (max-width: $lg-breakpoint)
      flex: auto
  ul
    position: absolute
    top: 105%
    width: 100%
    box-shadow: 2px 2px 10px rgba(34, 34, 34, 0.4)
    border-radius: 2px
    background-color: $white
    z-index: 3
    li
       padding: 0.7em 1em
       &:hover
        background-color: rgba(30, 116, 95, 0.1)
       b
        font-size: 14px
.select-wrap.error
  .select-option
    border-bottom: 1px solid $red
</style>
