<template>
  <div class="list-wrap" v-if="list && list.length > 0" >
  <div class="heading-wrap">
    <h4 class="label">
      Showing all {{ list.length }} results
    </h4>
    <div class="select sorting">
      <MHSelectbox :options="options" :selectedOption="selectedCategory" @selectOption="selectCategory" :isArray="true" />
    </div>
    </div>
    <section>
      <ProductColumn :items="list" />
    </section>
  </div>
</template>

<script>
import MHSelectbox from '@/components/molecules/MHSelectbox'
import ProductColumn from '@/components/molecules/ProductColumn'
export default {
  props: {
    list: {
      type: [Array, Object]
    }
  },
  components: {
    MHSelectbox,
    ProductColumn
  },
  data () {
    return {
      selectedCategory: 'DEFAULT SORTING',
      options: [
        {
          id: 1,
          value: 'Popularity'
        },
        {
          id: 2,
          value: 'Price- Low to High'
        },
         {
          id: 3,
          value: 'Price- High to low'
        }
      ]
    }
  },
  computed: {
   
  },
  methods: {
     selectCategory (value) {
      this.selectedCategory = value.value
      if(value.id == 2){
        this.list = this.list.sort((a,b) => {
          return a.Price - b.Price
        })
      } 
      else if(value.id == 3)
      {
        this.list = this.list.sort((a,b) => {
          return b.Price - a.Price
        })
      }
      else
      {
        return this.list
      }
     },
  }
}
</script>

<style lang="sass" scoped>
%text-props
  margin: 0
  @include font-source('Poppins', 13px, 600, $text-grey)
.list-wrap
  .heading-wrap
    display: flex
    align-items: center
    padding-bottom: 50px
    @media screen and (max-width: $md-breakpoint)
      flex-direction: column
    .label
      @extend %text-props
      font-size: 12px
      flex: 2
      display: flex
      justify-content: flex-start
      @media screen and (max-width: $ms-breakpoint)
        justify-content: center
    .select
      width: 185px
</style>